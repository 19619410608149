import React, { useState, useEffect } from "react";
import { database } from "../firebase/firebase"; // Adjust the path as necessary
import { ref, set, onValue } from "firebase/database";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../contexts/authContext";
import { getStorage, ref as firebaseStorageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import AdminSidebar from './AdminSidebar';

const AdminAddStationery = () => {
  const [bookName, setBookName] = useState("");
  const [bookPrice, setBookPrice] = useState("");
  const [bookImage, setBookImage] = useState(null);
  const [stationeryList, setStationeryList] = useState([]); // State to hold the list of stationery
  const storage = getStorage();
  const { logout } = useAuth();

  // Fetch stationery items from the database
  useEffect(() => {
    const stationeryKey = `Stationery/`;
    const unsubscribe = onValue(ref(database, stationeryKey), (snapshot) => {
      const data = snapshot.val() || {};
      const items = Object.entries(data).map(([key, value]) => ({
        id: key,
        ...value,
      }));
      setStationeryList(items); // Set the fetched stationery items
    });

    return () => unsubscribe(); // Clean up the subscription
  }, []);

  const addBook = async () => {
    if (!bookName || !bookPrice) {
      console.error("Stationery name and price must be filled.");
      return;
    }

    const stationeryKey = `Stationery/`; // Update the database path for stationery
    const newStationeryId = new Date().getTime();
    const newStationeryEntry = {
      id: newStationeryId,
      name: bookName,
      price: parseFloat(bookPrice),
    };

    // Handle image upload if provided
    if (bookImage) {
      const imageRef = firebaseStorageRef(storage, `stationery/${newStationeryId}`);
      try {
        await uploadBytes(imageRef, bookImage);
        const imageUrl = await getDownloadURL(imageRef);
        newStationeryEntry.imageUrl = imageUrl;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    await set(
      ref(database, `${stationeryKey}${bookName.replace(/[\s.]/g, "_")}`),
      newStationeryEntry
    );

    // Clear input fields
    setBookName("");
    setBookPrice("");
    setBookImage(null);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="admin-dashboard">
      <AdminSidebar handleLogout={handleLogout} />
      <main className="content">
        <h2 className="text-center">Admin Interface</h2>
        <div className="mb-4">
          <input
            type="text"
            className="form-control mb-2"
            value={bookName}
            onChange={(e) => setBookName(e.target.value)}
            placeholder="Stationery Name"
          />
          <input
            type="number"
            className="form-control mb-2"
            value={bookPrice}
            onChange={(e) => setBookPrice(e.target.value)}
            placeholder="Stationery Price"
          />
          <p>Upload Stationery Image</p>
          <input
            type="file"
            className="form-control mb-2"
            accept="image/*"
            onChange={(e) => setBookImage(e.target.files[0])}
          />
          <button className="btn button-order" onClick={addBook}>
            Add Stationery
          </button>
        </div>

        <h3 className="text-center">Stationery List</h3>
        <ul className="list-group">
          {stationeryList.map((item) => (
            <li key={item.id} className="list-group-item">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <strong>{item.name}</strong> - ${item.price}
                </div>
                {item.imageUrl && (
                  <img src={item.imageUrl} alt={item.name} style={{ width: '50px', height: '50px', marginLeft: '10px' }} />
                )}
              </div>
            </li>
          ))}
        </ul>
      </main>
    </div>
  );
};

export default AdminAddStationery;
