import React, { useState, useEffect } from "react";
import { database } from "../firebase/firebase"; // Adjust the path as necessary
import { ref, set, onValue, remove } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import "bootstrap/dist/css/bootstrap.min.css";
import Papa from "papaparse";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import {
  FaHome,
  FaChartBar,
  FaClipboardList,
  FaSignOutAlt,
  FaPlusSquare,
} from "react-icons/fa";
import "./AdminDashboard.css";
import AdminSidebar from './AdminSidebar';

const AdminAdd = () => {
  const [schools, setSchools] = useState([]);
  const [schoolName, setSchoolName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [grades, setGrades] = useState({});
  const [currentSchool, setCurrentSchool] = useState("");
  const [currentGrade, setCurrentGrade] = useState("");
  const [bookName, setBookName] = useState("");
  const [bookPrice, setBookPrice] = useState("");
  const [bookImage, setBookImage] = useState(null);
  const [itemType, setItemType] = useState("Compulsory"); // Default to 'Compulsory'
  const [booklist, setBooklist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const storage = getStorage();
  const [gradeBooks, setGradeBooks] = useState([]); // For books from 'Books' node
  const [schoolBooks, setSchoolBooks] = useState([]); // For books from 'schools' node
  const [selectedBook, setSelectedBook] = useState(''); // Track the selected book
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [stationeryList, setStationeryList] = useState([]); // State for stationery items
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const schoolsRef = ref(database, "schools/");
    onValue(schoolsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setSchools(Object.keys(data));
        setGrades(data);
      }
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    if (currentGrade) {
      const bookKey = `Books/grades/${currentGrade}/`;
      onValue(ref(database, bookKey), (snapshot) => {
        const books = snapshot.val() || {};
        const formattedBooks = Object.entries(books).map(([id, data]) => ({ id, ...data }));
        
        // Filter the books based on the selected itemType
        const filtered = formattedBooks.filter(book => book.type === itemType || itemType === "");
        
        setGradeBooks(formattedBooks);  // Keep all books
        setFilteredBooks(filtered);     // Only keep the filtered books
      });
    }
  }, [currentGrade, itemType]);  // Make sure to include `itemType` in the dependency array
  
  useEffect(() => {
    const stationeryRef = ref(database, "Stationery/");
    onValue(stationeryRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formattedStationery = Object.entries(data).map(([id, details]) => ({
          id,
          ...details,
        }));
        setStationeryList(formattedStationery); // Set the fetched stationery items
      }
    });
  }, []);
  
  useEffect(() => {
    if (currentSchool && currentGrade) {
      const bookKey = `schools/${currentSchool}/grades/${currentGrade}/books/`;
      onValue(ref(database, bookKey), (snapshot) => {
        const books = snapshot.val();
        setSchoolBooks(books ? Object.values(books) : []);
      });
    }
  }, [currentSchool, currentGrade]);

  useEffect(() => {
    if (currentGrade) {
      const bookKey = `Books/grades/${currentGrade}/`;
      onValue(ref(database, bookKey), (snapshot) => {
        const books = snapshot.val() || {};
        const formattedBooks = Object.entries(books).map(([id, data]) => ({ id, ...data }));
        setGradeBooks(formattedBooks);
        console.log("Grade Books:", formattedBooks); // Debugging line
      });
    }
  }, [currentGrade]);


  const addSchool = () => {
    const newGrades = [
      'PP1', 'PP2', 'Grade 1', 'Grade 2', 'Grade 3',
      'Grade 4', 'Grade 5', 'Grade 6', 'Grade 7',
      'Grade 8', 'Grade 9',
    ];
    set(ref(database, `schools/${schoolName}`), { grades: newGrades })
      .then(() => setSchoolName(''));
  };

  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  const handleAddBook = () => {
    if (!selectedBook) {
      alert("Please select a book first.");
      return;
    }
  
    // Find the selected book object from the gradeBooks list
    const bookToAdd = gradeBooks.find(book => String(book.id) === selectedBook);
  
    if (!bookToAdd) {
      console.error('Selected book not found in gradeBooks');
      return;
    }
  
    // Check if the book already exists in the schoolBooks list
    const bookAlreadyExists = schoolBooks.some(book => book.id === bookToAdd.id);
  
    if (bookAlreadyExists) {
      alert('This book is already added to the selected school and grade.');
      return;
    }
  
    // Set a default image if no imageUrl is present in the book data
    const bookImageUrl = bookToAdd.imageUrl || 'path_to_default_image'; // Replace with your default image path
  
    // Add the book to the school/grade in the database
    const newBookKey = `schools/${currentSchool}/grades/${currentGrade}/books/${bookToAdd.id}`;
    set(ref(database, newBookKey), {
      id: bookToAdd.id,
      name: bookToAdd.name,
      price: bookToAdd.price,
      type: bookToAdd.type,
      imageUrl: bookImageUrl // Use either the book's imageUrl or the default one
    }).then(() => {
      // Update the local state (schoolBooks) to include the newly added book
      setSchoolBooks(prevBooks => [...prevBooks, { ...bookToAdd, imageUrl: bookImageUrl }]);
      setSelectedBook(''); // Reset the selected book after it's added
      alert('Book added successfully.');
    }).catch((error) => {
      console.error("Error adding book to the school: ", error);
    });
  };
  
  const handleAddStationery = () => {
  if (!selectedBook || !quantity) {
    alert("Please select a stationery item and quantity.");
    return;
  }

  const stationeryToAdd = stationeryList.find(item => item.id === selectedBook);

  if (!stationeryToAdd) {
    console.error('Selected stationery not found');
    return;
  }

  const newStationeryKey = `schools/${currentSchool}/grades/${currentGrade}/stationery/${stationeryToAdd.id}`;
  set(ref(database, newStationeryKey), {
    id: stationeryToAdd.id,
    name: stationeryToAdd.name,
    price: stationeryToAdd.price,
    quantity: parseInt(quantity, 10) // Save quantity as a number
  }).then(() => {
    alert('Stationery added successfully.');
    setQuantity(1); // Reset quantity after adding
    setSelectedBook(''); // Reset selected stationery
  }).catch((error) => {
    console.error("Error adding stationery: ", error);
  });
};

  const deleteBook = (bookId) => {
    const bookKey = `schools/${currentSchool}/grades/${currentGrade}/books/${bookId}`;
    remove(ref(database, bookKey)).then(() => {
      setBooklist((prevBooks) =>
        prevBooks.filter((book) => book.id !== bookId)
      );
    });
  };

  const filteredSchools = searchQuery
    ? schools.filter((school) =>
        school.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : schools;

  return (
    <div className="admin-dashboard">
      <AdminSidebar handleLogout={handleLogout} />
      <main className="content">
        <h2 className="text-center">Admin Interface</h2>

        <div className="mb-4">
          <h3>Add School</h3>
          <input
            type="text"
            className="form-control"
            value={schoolName}
            onChange={(e) => setSchoolName(e.target.value)}
            placeholder="School Name"
          />
          <button className="btn btn-primary mt-2" onClick={addSchool}>Add School</button>
        </div>

        {schools.length > 0 && (
          <div className="mb-4">
            <h3>Add Books</h3>
            <select
              className="form-select mb-2"
              value={currentSchool}
              onChange={(e) => {
                setCurrentSchool(e.target.value);
                setCurrentGrade("");
                setBooklist([]); // Clear booklist when school changes
              }}
            >
              <option value="">Select School</option>
              {filteredSchools.map((school, index) => (
                <option key={index} value={school}>
                  {school}
                </option>
              ))}
            </select>
            <select
              className="form-select mb-2"
              value={currentGrade}
              onChange={(e) => setCurrentGrade(e.target.value)}
            >
              <option value="">Select Grade</option>
              {grades[currentSchool] && (
                <>
                  <option value="PP1">PP1</option>
                  <option value="PP2">PP2</option>
                  {Array.from({ length: 9 }, (_, i) => `Grade ${i + 1}`).map(
                    (grade, index) => (
                      <option key={index} value={grade}>
                        {grade}
                      </option>
                    )
                  )}
                </>
              )}
            </select>
          </div>
        )}
        
        <div className="mb-4">
  <h3>Filter by Book Type</h3>
  <select
    className="form-select mb-2"
    value={itemType}
    onChange={(e) => setItemType(e.target.value)}
  >
    <option value="">Select Book Type</option>
    <option value="Compulsory">Compulsory</option>
    <option value="Complementary">Complementary</option>
    
  </select>
</div>
{(filteredBooks.length > 0 || currentGrade) && currentGrade ? ( 
  <>
    <select
      className="form-select mb-2"
      value={selectedBook}
      onChange={(e) => {
        setSelectedBook(e.target.value);
      }}
    >
      <option value="">Select Book</option>
      {filteredBooks.map((book) => (
        <option key={book.id} value={book.id}>
          {book.name} - ${book.price} (Type: {book.type})
        </option>
      ))}
    </select>

    {/* Add Book Button */}
    <button
      className="btn btn-primary"
      onClick={handleAddBook}
      disabled={!selectedBook}
    >
      Add Book
    </button>
  </>
) : (
  <p>No books available for the selected grade.</p>
)}


        <h3 className="mt-4">
          Book List for {currentSchool} - {currentGrade}
        </h3>
        {schoolBooks.length > 0 ? (
          <ul className="list-group">
            {schoolBooks.map((book) => (
              <li
                key={book.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <div>
                  <img
                    src={book.imageUrl}
                    alt={book.name}
                    style={{ width: "50px", marginRight: "10px" }}
                  />
                  {book.name} - {book.price} (Type: {book.type})
                </div>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteBook(book.id)}
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No books available for the selected school and grade.</p>
        )}


      </main>
      
    </div>
  );
};

export default AdminAdd;
