import { auth, database } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut
} from "firebase/auth";
import { ref, set } from 'firebase/database'; // Import Realtime Database functions

export const doCreateUserWithEmailAndPassword = async (email, password) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  await sendEmailVerification(auth.currentUser); // Send verification email after account creation
  return userCredential;
};

export const doSignInWithEmailAndPassword = async (email, password) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  const user = userCredential.user;
  
  if (!user.emailVerified) {
    await signOut(auth);  // Sign out the user if the email is not verified
    throw new Error('Email not verified');
  }
  
  return userCredential;
};

export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const user = result.user;

  // Add user to Realtime Database
  const userRef = ref(database, `users/${user.uid}`);
  await set(userRef, {
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    role: "user", // Default role; adjust as necessary
  });

  return result;
};

export const doSignOut = () => {
  return signOut(auth);
};

export const doPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset email sent");
  } catch (error) {
    if (error.code === 'auth/user-not-found') {
      throw new Error('No account found with this email.');
    } else {
      throw error;
    }
  }
};

export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};

export { auth };
