import React, { useState, useEffect } from "react";
import { ref, onValue, push, set } from "firebase/database";
import { database } from "../firebase/firebase";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { createOrder } from "../shipdayService";
import { useAuth } from "../contexts/authContext";
import { useCart } from "../contexts/cartContext";
import "bootstrap/dist/css/bootstrap.min.css";
import whatPng from "../images/file.png";
import whaPng from "../images/SD.png";
import "./Styles/cart.css";
import BottomNav from "./bottomNav";
import Header from "./upperNav";
import {
  FaShoppingCart,
  FaUser,
  FaHome,
  FaSignOutAlt,
  FaSearch,
  FaClipboardList,
} from "react-icons/fa";

function Checkout() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [houseName, setHouseName] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { cart, removeFromCart } = useCart();
  const [cartItems, setCartItems] = useState([]);
  const { currentUser } = useAuth();
  const [schoolName, setSchoolName] = useState("");
  const location = useLocation();
  const [bookQuantity, setBookQuantity] = useState("");

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  useEffect(() => {
    // Check if the user has cart items and came from the checkout page
    if (currentUser) {
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);
      onValue(cartRef, (snapshot) => {
        const data = snapshot.val();
        setCartItems(data ? data : []);

        // Redirect if no cart items or not from checkout
        if ((!data || data.length === 0) && !location.state?.fromCheckout) {
          navigate("/cart");
        }
      });
    } else {
      navigate("/cart");
    }
  }, [currentUser, location.state, navigate]);

  useEffect(() => {
    if (currentUser) {
      // Fetch cart items
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);
      onValue(cartRef, (snapshot) => {
        const data = snapshot.val();
        // Check if data is an array or not null
        const cartArray = Array.isArray(data) ? data : [];
        setCartItems(cartArray);
        // Extract unique school names from cart items
        const schoolNames = [...new Set(cartArray.map(item => item.school))];
        setSchoolName(schoolNames.join(", ")); // Join multiple schools if necessary
      });
    } else {
      setCartItems([]);
      setSchoolName("");
    }
  }, [currentUser]);

  const handlePlaceOrder = async () => {
    console.log("Starting order placement");
    setIsLoading(true);
    setError("");
    setSuccessMessage("");

    if (!currentUser) {
      setError("User not authenticated. Please log in.");
      setIsLoading(false);
      return;
    }

    if (!firstName || !lastName || !streetName || !email || !phone) {
      setError("Please fill in all required fields.");
      setIsLoading(false);
      return;
    }

    const generateOrderNumber = () =>
      Math.random().toString(36).substr(2, 8).toUpperCase();

    const orderItems = cartItems.map((item) => ({
      name: item.name,
      unitPrice: item.price,
      quantity: item.quantity || 1, // Use the quantity from the cart item
    }));
    

    console.log("Cart items:", cartItems);

    const totalCost = cartItems.reduce(
      (total, item) => total + (parseFloat(item.price) || 0) * (item.quantity || 1),
      0
    );
    
    
    
    const deliveryFee = totalCost < 3000 ? 300 : totalCost * 0.1;
    const totalOrderCost = totalCost + deliveryFee;
    
    console.log("Calculated total cost:", totalCost);

    if (isNaN(totalOrderCost)) {
      console.error("Total order cost is NaN. Cart items:", cartItems);
      setError(
        "Error calculating total cost. Please try again or contact support."
      );
      setIsLoading(false);
      return;
    }

    const orderData = {
      orderNumber: generateOrderNumber(),
      customerName: `${firstName} ${lastName}`,
      customerAddress: `${houseName}, ${streetName}, ${postalCode}, ${city}, Kenya`,
      customerEmail: email,
      customerPhoneNumber: phone,
      restaurantName: schoolName,
      deliveryFee: deliveryFee,
      orderId: generateOrderNumber(),
      orderItem: orderItems,
      totalOrderCost: totalOrderCost,
      deliveryInstruction: additionalInfo || "",
    };

    console.log("Order data:", orderData);

    try {
      // Create order in Shipday API
      console.log("Calling Shipday API");
      const orderResponse = await createOrder(orderData);
      console.log("Order created successfully in Shipday:", orderResponse);

      // Save order to Firebase
      console.log("Saving order to Firebase");
      const ordersRef = ref(database, `orders/${currentUser.uid}`);
      const newOrderRef = push(ordersRef);
      await set(newOrderRef, {
        ...orderData,
        timestamp: Date.now(),
        status: "pending",
      });

      console.log("Order saved to Firebase successfully");

      setSuccessMessage("Order created successfully and saved to database!");

      // Clear the cart after successful order
      console.log("Clearing cart");
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);
      await set(cartRef, null);
      setCartItems([]);

      console.log("Cart cleared successfully");

      // Redirect to the orders page
      navigate("/orders");
    } catch (error) {
      setError("Failed to create order. Please try again.");
      console.error("Failed to create order:", error);
      if (error.code) {
        console.error("Firebase error code:", error.code);
      }
      if (error.message) {
        console.error("Error message:", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const totalCost = cartItems.reduce(
    (total, item) => total + (parseFloat(item.price) || 0) * (item.quantity || 1),
    0
  );
  const deliveryFee = totalCost < 3000 ? 300 : totalCost * 0.1;
  const totalOrderCost = totalCost + deliveryFee;

  return (
    <div>
      <Header handleLogout={handleLogout} />

      <div className="container my-5 py-5">
        <section>
          <div className="row">
            <div className="col-md-8">
              <div className=" mb-4">
                <div className="card-body">
                  <p className="text-uppercase fw-bold mb-3">
                    Delivery address
                  </p>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label" htmlFor="firstName">
                        First name
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        className="form-control"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="col-md-6 mb-4">
                      <label className="form-label" htmlFor="lastName">
                        Last name
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        className="form-control"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label" htmlFor="phone">
                        Phone
                      </label>
                      <input
                        type="text"
                        id="phone"
                        className="form-control"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label" htmlFor="city">
                        City / Town
                      </label>
                      <input
                        type="text"
                        id="city"
                        className="form-control"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="form-label" htmlFor="postalCode">
                        Location
                      </label>
                      <input
                        type="text"
                        id="postalCode"
                        className="form-control"
                        placeholder="eg. Ngara, westlands"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label" htmlFor="streetName">
                        Street Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={streetName}
                        onChange={(e) => setStreetName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="form-label" htmlFor="houseName">
                        House Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="eg. Cresent Apartment"
                        value={houseName}
                        onChange={(e) => setHouseName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="form-label" htmlFor="additionalInfo">
                      Additional Information
                    </label>
                    <textarea
                      id="additionalInfo"
                      className="form-control"
                      value={additionalInfo}
                      onChange={(e) => setAdditionalInfo(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-4">
                <div className="">
                  <h5 className="mb-3">Summary</h5>

                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                      School
                      <span>{schoolName}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                      
                    </li>
                    {/* Render cart items only if cartItems is not null */}
                    {cartItems &&
                      cartItems.map((item, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0"
                        >
                          {item.name}
                          <strong>{item.quantity}</strong>
                          <span>KES {(item.price * (item.quantity || 1)).toFixed(2)}</span>

                        </li>
                      ))}
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                      Delivery Fee
                      <span>KES {deliveryFee.toFixed(2)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <div>
                        <strong>Total Amount </strong>
                      </div>
                      <span>
                        <strong>
                          {totalOrderCost.toFixed(2)}
                        </strong>
                      </span>
                    </li>
                  </ul>

                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    onClick={handlePlaceOrder}
                    disabled={isLoading}
                  >
                    {isLoading ? "Placing Order..." : "Place Order"}
                  </button>

                  {error && <p className="text-danger mt-3">{error}</p>}
                  {successMessage && (
                    <p className="text-success mt-3">{successMessage}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="bg-light text-center py-3">
          <div className="container">
            <p className="mb-0">
              &copy; 2024 Booklist Shop. All rights reserved.
            </p>
          </div>
        </footer>
        <BottomNav handleLogout={handleLogout} />

      </div>
    </div>
  );
}

export default Checkout;