import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaShoppingCart,
  FaHome,
  FaSignOutAlt,
  FaSearch,
  FaClipboardList,
  FaBars,
} from 'react-icons/fa';
import whatPng from '../images/file.png';
import whaPng from '../images/SD.png';
import './Styles/Sidebar.css'; // Separate CSS file for header-specific styling

const Header = ({ handleLogout }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <header className="nus">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 col-md-3">
            <img src={whatPng} className="logo" alt="Logo" />
            <img
              src={whaPng}
              className="logo"
              style={{
                width: '80px',
                height: '120px',
                objectFit: 'cover',
                borderRadius: '4px',
              }}
              alt="Logo"
            />
          </div>
          <div className="col-md-9">
            <nav className="navbar navbar-expand-lg navbar-primary">
              <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item me-3">
                    <Link className="nav-link" to="/home">
                      <FaHome className="icon" /> Home
                    </Link>
                  </li>
                  <li className="nav-item me-3">
                    <Link className="nav-link" to="/school">
                      <FaSearch className="icon" /> School
                    </Link>
                  </li>
                  <li className="nav-item me-3">
                    <Link className="nav-link" to="/cart">
                      <FaShoppingCart /> Cart
                    </Link>
                  </li>
                  <li className="nav-item me-3">
                    <Link className="nav-link" to="/orders">
                      <FaClipboardList /> Orders
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" onClick={toggleSidebar}>
                      <FaBars className="icon" />
                      More
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          {/* Sidebar Component */}
          <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
            <div className="sidebar-content">
              <Link className="nav-link" to="/orders">
                <FaSearch className="icon" /> Find Books
              </Link>
              <Link className="nav-link" to="/orders">
                <FaClipboardList className="icon" /> Orders
              </Link>
              <Link className="nav-link" onClick={handleLogout} to="/sign-in">
                <FaSignOutAlt className="icon" /> Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
