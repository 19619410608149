import React, { createContext, useContext, useState } from 'react';

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  const addToCart = (book, schoolName) => {
    // Check if the book already exists in the cart
    const existingBookIndex = cart.findIndex(
      (item) => item.name === book.name && item.school === schoolName
    );

    if (existingBookIndex !== -1) {
      // If the book exists, update its quantity
      const updatedCart = [...cart];
      updatedCart[existingBookIndex].quantity += 1;
      setCart(updatedCart);
    } else {
      // If the book doesn't exist, add it with quantity 1
      setCart([...cart, { ...book, quantity: 1, school: schoolName }]);
    }
  };

  const removeFromCart = (bookName, schoolName) => {
    setCart(
      cart.filter(
        (item) => item.name !== bookName || item.school !== schoolName
      )
    );
  };

  const updateCartItem = (bookId, newQuantity) => {
    setCart(
      cart.map((item) =>
        item.id === bookId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider
      value={{ cart, addToCart, removeFromCart, updateCartItem, clearCart }}
    >
      {children}
    </CartContext.Provider>
  );
};