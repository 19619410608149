import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Styles/signup.css';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBIcon,
  MDBFooter
} from 'mdb-react-ui-kit';
import { ref, set, update } from 'firebase/database';
import { database } from '../firebase/firebase';
import { doCreateUserWithEmailAndPassword, auth } from '../firebase/auth';
import { signOut } from 'firebase/auth';

function App() {
  const [name, setName] = useState(''); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [formError, setFormError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validatePassword(password)) {
      setPasswordError('Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.');
      return;
    }
  
    if (password !== confirmPassword) {
      setFormError('Passwords do not match.');
      return;
    }
  
    setPasswordError('');
    setFormError('');
  
    try {
      // Create user with email and password
      const userCredential = await doCreateUserWithEmailAndPassword(email, password);
      const user = userCredential.user;
  
      // Assign default role "user"
      const userRole = "user";
  
      // Set user data in Realtime Database
      const userRef = ref(database, `users/${user.uid}`);
      await set(userRef, {
        displayName: name, 
        email: user.email,
        role: userRole,
        isEmailVerified: false 
      });
  
      // Optionally, sign out the user
      await signOut(auth);
  
      alert('Account created successfully! A verification email has been sent to your email address. Please verify your email before logging in.');
      navigate('/sign-in');
    } catch (error) {
      console.error('Error creating user:', error);
  
      if (error.code === 'auth/email-already-in-use') {
        setFormError('An account with this email already exists.');
      } else if (error.code === 'auth/weak-password') {
        setFormError('Password is too weak. Please choose a stronger password.');
      } else {
        setFormError('Failed to create account. Please try again later.');
      }
    }
  };

  // Function to update isEmailVerified in the database
  const handleEmailVerification = async () => {
    try {
      // Get the currently signed-in user
      const user = auth.currentUser;

      // Update the isEmailVerified field in the database
      const userRef = ref(database, `users/${user.uid}`);
      await update(userRef, { isEmailVerified: true });

      // Optionally, sign out the user
      await signOut(auth);

      alert('Email verified successfully!');
      navigate('/sign-in'); // Redirect to the sign-in page
    } catch (error) {
      console.error('Error verifying email:', error);
      alert('Failed to verify email. Please try again later.');
    }
  };

  return (
    <div>
      <MDBContainer fluid className='p-4 huh overflow-hidden'>
        <MDBRow>
          <MDBCol md='6'>
            <h1 className="my-5 display-3 fw-bold ls-tight px-3" style={{color: 'hsl(218, 81%, 95%)'}}>
              The best offer <br />
              <span style={{color: 'hsl(218, 81%, 75%)'}}>Pay on Delivery</span>
            </h1>
            <p className='px-3' style={{color: 'hsl(218, 81%, 85%)'}}>
              Having trouble buying your Kids Booklist? Worry no more. Order your child's booklist and have them delivered to your doorstep.
            </p>
          </MDBCol>
          <MDBCol md='6' className='position-relative'>
            <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
            <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
            <MDBCardBody className='p-5'>
              <MDBInput 
                wrapperClass='mb-4 white-label' 
                label='First Name' 
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <MDBInput 
                wrapperClass='mb-4 white-label' 
                label='Email' 
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <MDBInput 
                wrapperClass='mb-4 white-label' 
                label='Password' 
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <MDBInput 
                wrapperClass='mb-4 white-label' 
                label='Confirm Password' 
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {passwordError && <div className="text-danger mb-4">{passwordError}</div>}
              {formError && <div className="text-danger mb-4">{formError}</div>}
              <div className='d-flex justify-content-center mb-4'>
                <MDBCheckbox 
                  name='flexCheck' 
                  value='' 
                  id='flexCheckShowPassword' 
                  label={<span className='white-label'>Show Password</span>} 
                  checked={showPassword} 
                  onChange={() => setShowPassword(!showPassword)} 
                  wrapperClass='custom-checkbox'
                />
              </div>
              <MDBBtn
                className='w-100 mb-4'
                size='sm'
                style={{
                  backgroundColor: '#28a745',
                  borderColor: '#28a745',
                  color: '#ffffff',
                  fontSize: '0.875rem',
                  padding: '10px 20px'
                }}
                onClick={handleSubmit}
              >
                Sign up
              </MDBBtn>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBFooter style={{ backgroundColor: 'silver'}} className='text-center text-lg-start text-muted'>
        <section className=''>
          <MDBContainer className='text-center text-md-start mt-5'>
            <MDBRow className='mt-3'>
              <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>
                  <MDBIcon icon="gem" className="me-3" />
                  Skool Booklist
                </h6>
                <p>
                Struggling to find your childs booklist at the bookshop?Place your order and have the books delivered to your door step. 
                </p>
              </MDBCol>
              <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
                <p>
                  <a href='#!' className='text-reset'>
                    CBC curriculum Books
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    International Based curriculum books
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    Stationery
                  </a>
                </p>
              </MDBCol>
              <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
                <p>
                  <a href='#!' className='text-reset'>
                    Pricing
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    Orders
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    Help
                  </a>
                </p>
              </MDBCol>
              <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                <p>
                  <MDBIcon icon="home" className="me-2" />
                  Nairobi, Kenya
                </p>
                <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  skoolbooklist.com
                </p>
                <p>
                  <MDBIcon icon="phone" className="me-3" /> + 254 797 230 330
                </p>
                <p>
                  <MDBIcon icon="print" className="me-3" /> + 254 705 769 873
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </MDBFooter>
    </div>
  );
}

export default App;