import React, { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { useAuth } from "../../contexts/authContext";
import { database } from "../../firebase/firebase";
import { useParams } from "react-router-dom";
import { FaShoppingCart, FaHome, FaUser, FaSignOutAlt, FaSearch, FaClipboardList } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { trackOrder } from "../../shipdayService";

import Header from "../upperNav";
import BottomNav from "../bottomNav";

function OrderDetails() {
  const { orderId } = useParams(); 
  const [order, setOrder] = useState(null);
  const [orderStatus, setOrderStatus] = useState("");
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [showLoginSignup, setShowLoginSignup] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    if (currentUser && orderId) {
      const orderRef = ref(database, `orders/${currentUser.uid}/${orderId}`);

      const fetchOrder = () => {
        onValue(orderRef, async (snapshot) => {
          const data = snapshot.val();
          if (data) {
            setOrder(data);

            // Fetch the order status from the tracking service
            try {
              const status = await trackOrder(data.orderNumber); // Fetch the status
              setOrderStatus(status); // Set the status in the state
            } catch (error) {
              console.error("Error fetching order status:", error);
              setOrderStatus("Error fetching status");
            }
          }
        }, (error) => {
          console.error("Error fetching order:", error);
        });
      };

      fetchOrder(); // Initial fetch of order details
    }
  }, [currentUser, orderId]);

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container my-5">
       <Header handleLogout={handleLogout} />
      <h1>Order Details</h1>
      <p><strong>Order Number:</strong> {order.orderNumber}</p>
      <p><strong>Status:</strong> {orderStatus || "Fetching status..."}</p>
      <p><strong>Total Cost:</strong> ${order.totalOrderCost}</p>
      <p><strong>Delivery Address:</strong> {order.customerAddress}</p>
      <p><strong>Delivery Instructions:</strong> {order.deliveryInstruction || "N/A"}</p>
      <h2>Order Items</h2>
      <ul>
        {order.orderItem.map((item, index) => (
          <li key={index}>
            {item.name} - ${item.unitPrice} x {item.quantity}
          </li>
        ))}
      </ul>
      <footer className="bg-light text-center py-3">
        <div className="container">
          <p className="mb-0">&copy; 2024 Booklist Shop. All rights reserved.</p>
        </div>
      </footer>
      <BottomNav handleLogout={handleLogout} />
    </div>
  );
}

export default OrderDetails;
