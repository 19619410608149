// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";
import { getDatabase, ref, set } from 'firebase/database'; // Import Realtime Database functions

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD7mpYZmDgkXkSxn99zccLsPpWwt2BXFUU",
  authDomain: "booklist-online-bookstore.firebaseapp.com",
  projectId: "booklist-online-bookstore",
  storageBucket: "booklist-online-bookstore.appspot.com",
  messagingSenderId: "366592797824",
  appId: "1:366592797824:web:3c7f5cea890fb0ea458a65",
  measurementId: "G-THRTVTEKPE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const database = getDatabase(app); // Initialize Realtime Database


export { app, auth, database, signOut };
