// privateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './contexts/authContext';

function PrivateRoute({ element: Component, requiredRole }) {
  const { currentUser, loading } = useAuth();

  if (loading) return <div>Loading...</div>; // Optionally show a loading state

  // Check if the user is logged in and has the required role
  if (currentUser && (requiredRole ? currentUser.role === requiredRole : true)) {
    return Component;
  }

  // Redirect based on the role or login status
  return currentUser ? <Navigate to="/home" replace /> : <Navigate to="/sign-in" replace />;
}

export default PrivateRoute;
