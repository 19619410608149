import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, signOut } from "../firebase/firebase"; // Ensure signOut is imported
import { onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database"; // Import Firebase Realtime Database methods

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isEmailUser, setIsEmailUser] = useState(false);
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);

  async function initializeUser(user) {
    if (user) {
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}/role`); // Path to user role
      onValue(userRef, (snapshot) => {
        const role = snapshot.val();
        setCurrentUser({ ...user, role }); // Include role in the user object

        // Check if provider is email and password login
        const isEmail = user.providerData.some(
          (provider) => provider.providerId === "password"
        );
        setIsEmailUser(isEmail);

        // Check if the auth provider is Google
        const isGoogle = user.providerData.some(
          (provider) => provider.providerId === "google.com"
        );
        setIsGoogleUser(isGoogle);

        setUserLoggedIn(true);
        setLoading(false); // Stop loading after setting user details
      });
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
      setLoading(false);
    }
  }

  const logout = async () => {
    try {
      await signOut(auth); // Sign out from Firebase
      setUserLoggedIn(false); // Update the state
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const value = {
    userLoggedIn,
    isEmailUser,
    isGoogleUser,
    currentUser,
    setCurrentUser,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
