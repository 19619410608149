import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Styles/resetpassword.css';
import {
  MDBBtn,
  MDBContainer,
  MDBCol,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBFooter,
  MDBRow
} from 'mdb-react-ui-kit';
import { doPasswordReset } from '../firebase/auth';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [formError, setFormError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormError('');
    setSuccessMessage('');

    try {
      await doPasswordReset(email);
      setSuccessMessage('Password reset email sent successfully! Please check your inbox.');
      setEmail('');
    } catch (error) {
      console.error('Error resetting password:', error);
      if (error.code === 'auth/user-not-found') {
        setFormError('No account found with this email.');
      } else if (error.code === 'auth/invalid-email') {
        setFormError('Invalid email address.');
      } else {
        setFormError('Failed to reset password. Please try again later.');
      }
    }
  };

  return (
    <div>
      <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>
        <MDBCol>
          <h1 className="my-5 display-3 fw-bold ls-tight px-3" style={{color: 'hsl(218, 81%, 95%)'}}>
            Reset Your Password <br />
            <span style={{color: 'hsl(218, 81%, 75%)'}}>Quick and Secure</span>
          </h1>
          <p className='px-3' style={{color: 'hsl(218, 81%, 85%)'}}>
            Enter your registered email address below, and we will send you instructions to reset your password.
          </p>
        </MDBCol>
        <MDBCol md='6' className='position-relative'>
          <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
          <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
          <MDBCardBody className='p-5'>
            <MDBInput 
              wrapperClass='mb-4 white-label' 
              label='Email' 
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {formError && <div className="text-danger mb-4">{formError}</div>}
            {successMessage && <div className="text-success mb-4">{successMessage}</div>}
            <MDBBtn
              className='w-100 mb-4'
              size='sm'
              style={{
                backgroundColor: '#007bff',
                borderColor: '#007bff',
                color: '#ffffff',
                fontSize: '0.875rem',
                padding: '10px 20px'
              }}
              onClick={handleSubmit}
            >
              Reset Password
            </MDBBtn>
          </MDBCardBody>
        </MDBCol>
      </MDBContainer>
      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
        <section className=''>
          <MDBContainer className='text-center text-md-start mt-5'>
            <MDBRow className='mt-3'>
              <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>
                  <MDBIcon icon="gem" className="me-3" />
                  Skool Booklist
                </h6>
                <p>
                  Here you can use rows and columns to organize your footer content. Lorem ipsum dolor sit amet,
                  consectetur adipisicing elit.
                </p>
              </MDBCol>
              <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
                <p>
                  <a href='#!' className='text-reset'>
                    CBC curriculum Books
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    International Based curriculum books
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    Stationery
                  </a>
                </p>
              </MDBCol>
              <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
                <p>
                  <a href='#!' className='text-reset'>
                    Pricing
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    Orders
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    Help
                  </a>
                </p>
              </MDBCol>
              <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                <p>
                  <MDBIcon icon="home" className="me-2" />
                  Nairobi, Kenya
                </p>
                <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  skoolbooklist.com
                </p>
                <p>
                  <MDBIcon icon="phone" className="me-3" /> + 254 722 446 858
                </p>
                <p>
                  <MDBIcon icon="print" className="me-3" /> + 254 797 230 330
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </MDBFooter>
    </div>
  );
}

export default ResetPassword;
