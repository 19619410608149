import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaSignOutAlt,
  FaClipboardList,
  FaPlusSquare,
  FaChartBar,
  FaBars,
} from "react-icons/fa";
import "./AdminSidebar.css";


const AdminSidebar = ({ handleLogout }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="admin-sidebar-container">
      {/* Menu Toggle Button */}
      <button className="menu-toggle" onClick={toggleSidebar}>
        <FaBars />
      </button>

      {/* Sidebar */}
      <aside className={`navSidebar ${isSidebarOpen ? "active" : ""}`}>
        <h2>Admin Dashboard</h2>
        <nav>
          <ul>
            <li>
              <Link to="/admin">
                <FaHome /> Home
              </Link>
            </li>
            <li>
              <Link to="/admin/orders">
                <FaClipboardList /> Orders Per School
              </Link>
            </li>
            <li>
              <Link to="/admin/add">
                <FaPlusSquare /> Add Books to school
              </Link>
            </li>
            <li>
              <Link to="/admin/add-stationery">
                <FaPlusSquare /> Add Stationery
              </Link>
            </li>
            <li>
              <Link to="/admin/add-books">
                <FaPlusSquare /> Add Books
              </Link>
            </li>
            <li>
              <Link to="/admin/stats">
                <FaChartBar /> Statistics
              </Link>
            </li>
            <li>
              <Link onClick={handleLogout} to="/login">
                <FaSignOutAlt /> Logout
              </Link>
            </li>
          </ul>
        </nav>
      </aside>
    </div>
  );
};

export default AdminSidebar;
