import React, { useState } from 'react';
import { useAuth } from '../contexts/authContext';
import { Navigate, useNavigate } from 'react-router-dom'; 
import { doSignInWithEmailAndPassword, doSignInWithGoogle, doSignOut } from '../firebase/auth';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBFooter,
  MDBIcon,
} from 'mdb-react-ui-kit';

import { ref, get, set, serverTimestamp } from 'firebase/database';
import { database, ServerValue } from '../firebase/firebase'; // Correct import from your Firebase config
import g from '../images/g.png';
import './Styles/signup.css';  

const Login = () => {
  const { userLoggedIn, currentUser } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const redirectBasedOnRole = async (user) => {
    try {
      const roleRef = ref(database, `users/${user.uid}/role`);
      const roleSnapshot = await get(roleRef);

      if (roleSnapshot.exists()) {
        const userRole = roleSnapshot.val();

        if (userRole === 'admin') {
          navigate('/admin');
        } else {
          navigate('/home');
        }
      } else {
        console.error('No role found for this user');
        navigate('/home');
      }
    } catch (error) {
      console.error('Error fetching user role:', error);
      navigate('/home');
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        const userCredential = await doSignInWithEmailAndPassword(email, password);
        const user = userCredential.user;

        redirectBasedOnRole(user);
      } catch (error) {
        setErrorMessage(error.message);
        setIsSigningIn(false);
      }
    }
  };

  const onGoogleSignIn = async (e) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      try {
        const userCredential = await doSignInWithGoogle();
        const user = userCredential.user;

        // Check if user data already exists in the database
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);

        if (!snapshot.exists()) {
          const userData = {
            name: user.displayName,
            email: user.email,
            role: 'user', // or assign a specific role if needed
            createdAt: serverTimestamp(), // Set timestamp using serverTimestamp()
          };
          await set(userRef, userData);
          
        }

        redirectBasedOnRole(user);
      } catch (error) {
        setErrorMessage(error.message);
        setIsSigningIn(false);
      }
    }
  };

  return (
    <div>
    <MDBContainer fluid className='p-4 huh
     overflow-hidden'>
      <MDBRow>
        <MDBCol md='6' className='text-center text-md-start d-flex flex-column justify-content-center'>
          <h1 className="my-5 display-3 fw-bold ls-tight px-3" style={{ color: 'hsl(218, 81%, 95%)' }}>
            The best offer <br />
            <span style={{ color: 'hsl(218, 81%, 75%)' }}>Pay on Delivery</span>
          </h1>
          <p className='px-3' style={{ color: 'hsl(218, 81%, 85%)' }}>
            Having trouble buying your Kids school Booklist? Worry no more.
            Order your child's booklist and have them delivered to your doorstep.
          </p>
        </MDBCol>

        <form className="form-signin col-12 col-md-8 col-lg-4 border border-light-subtle bg-light p-4 rounded" onSubmit={onSubmit}>
  <h2 className="text-center mb-4">Sign In</h2>
  {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

  <div className="mb-3">
    <label>Email address</label>
    <input
      type="email"
      className="form-control"
      placeholder="Enter email"
      required
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
  </div>

  <div className="mb-3">
    <label>Password</label>
    <div className="input-group">
      <input
        type={showPassword ? "text" : "password"} // Toggle between text and password
        className="form-control"
        placeholder="Enter password"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button
        type="button"
        className="btn btn-outline-secondary"
        onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
      >
        {showPassword ? "Hide" : "Show"}
      </button>
    </div>
  </div>

  <div className="d-flex justify-content-center mb-3">
            <MDBBtn type="submit" className="checkout-button" disabled={isSigningIn}>
              {isSigningIn ? 'Signing In...' : 'Submit'}
            </MDBBtn>
          </div>

  <MDBBtn
  onClick={onGoogleSignIn}
  className="btn d-flex align-items-center justify-content-center gap-2"
  style={{
    backgroundColor: '#fff',  // White background
    color: '#5f6368',  // Grey text (Google's button text color)
    border: '1px solid #dadce0',  // Light grey border
    padding: '0.5rem 1.5rem',  // Adjusted padding
    borderRadius: '0.375rem',  // Slightly smaller border-radius
    fontWeight: 'bold',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    width: 'auto',  // Set to auto for dynamic width
    maxWidth: '300px',  // Maximum width for the button
    position: 'relative',
    textAlign: 'center',  // Center text alignment
  }}
  disabled={isSigningIn}
>
  <div className="google-logo" style={{ width: '24px', height: '24px', marginRight: '0.5rem' }}>
    <img
      src={g}
      alt="Google logo"
      style={{ width: '120%', height: '120%' , marginRight: '0.5rem' }}  // Adjusted to fit container
    />
  </div>
  
  {isSigningIn ? 'Signing In...' : 'Sign in with Google'}
</MDBBtn>




  <p className="text-center mt-3">
    Forgot <a href="/reset-password">password?</a>
  </p>
  <p className="text-center">
    Don't have an account? <a href="/sign-up">Sign up here</a>
  </p>
</form>

      </MDBRow>

    </MDBContainer>
    <MDBFooter style={{ backgroundColor: 'silver'}} className='text-center text-lg-start text-muted'>
        <section className=''>
          <MDBContainer className='text-center text-md-start mt-5'>
            <MDBRow className='mt-3'>
              <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>
                  <MDBIcon icon="gem" className="me-3" />
                  Skool Booklist
                </h6>
                <p>
                Struggling to find your childs school booklist at the bookshop?Place your order and have the books delivered to your door step. 
                </p>
              </MDBCol>
              <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
                <p>
                  <a href='#!' className='text-reset'>
                    CBC curriculum Books
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    International Based curriculum books
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    Stationery
                  </a>
                </p>
              </MDBCol>
              <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
                <p>
                  <a href='#!' className='text-reset'>
                    Pricing
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    Orders
                  </a>
                </p>
                <p>
                  <a href='#!' className='text-reset'>
                    Help
                  </a>
                </p>
              </MDBCol>
              <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                <p>
                  <MDBIcon icon="home" className="me-2" />
                  Nairobi, Kenya
                </p>
                <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  skoolbooklist.com
                </p>
                <p>
                  <MDBIcon icon="phone" className="me-3" /> + 254 797 230 330
                </p>
                <p>
                  <MDBIcon icon="print" className="me-3" /> + 254 705 769 873
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </MDBFooter>
    </div>
  );
};

export default Login;
