import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/Home.css";
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  Card,
  Table,
  Collapse,
} from "react-bootstrap";
import {
  FaShoppingCart,
  FaHome,
  FaSignOutAlt,
  FaSearch,
  FaClipboardList,
  FaUniversity,
  FaBars,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext";
import BottomNav from "./bottomNav";
import Header from "./upperNav";
import { getDatabase, ref, onValue } from "firebase/database"; // Import Firebase database functions

import two from "../images/2.jpg";
import en from "../images/en.jpeg";
import setbook from "../images/setbook.webp";
import four from "../images/4.jpeg";

const Home = () => {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [showLoginSignup] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [userName, setUserName] = useState("");
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    if (currentUser) {
      const db = getDatabase();
      const userRef = ref(db, `users/${currentUser.uid}/displayName`);
      onValue(userRef, (snapshot) => {
        const name = snapshot.val();
        setUserName(name || "User");
      });
    }
  }, [currentUser]);

  useEffect(() => {
    const db = getDatabase();
    const schoolsRef = ref(db, "schools");
    onValue(schoolsRef, (snapshot) => {
      const data = snapshot.val();
      console.log("Fetched schools data:", data);
      if (data) {
        const schoolArray = Object.keys(data).map((key) => ({
          id: key,
          name: data[key].name || key, // Use name from data if available, otherwise use key
          ...data[key],
        }));
        console.log("Mapped schools array:", schoolArray);
        // Shuffle array and get the first 4 schools
        const shuffled = schoolArray.sort(() => 0.5 - Math.random());
        setSchools(shuffled.slice(0, 3));
      }
    });
  }, []);

  const handleSchoolClick = () => {
    navigate(`/school`); // Navigate to search page with school ID
  };
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const featuredProducts = [
    {
      title: "Readers",
      image: setbook,
      price: "",
    },
    {
      title: "Encyclopedia",
      image: en,
      price: "",
    },
    {
      title: "Course Books",
      image: two,
      price: "",
    },
    {
      title: "Stationeries",
      image: four,
      price: "",
    },
  ];

  return (
    <div className="container-fluid">
      <Header handleLogout={handleLogout} />

      <main className="mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section">
                
                <div className="hero-content">
                  <h1>Welcome Back, {userName}!</h1>
                  <p>
                    Save the hassle, order your child's booklist from the
                    comfort of your home.
                  </p>
                  <Link to="/school" className="btn btn-primary btn-lg">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <h2>Featured Schools</h2>
            <div className="common-schools">
              {schools.length > 0 ? (
                schools.map((school) => (
                  <div
                    key={school.id}
                    className="school-item"
                    onClick={() => handleSchoolClick()} // Add onClick handler
                  >
                    <FaUniversity className="school-icon" />
                    <Card.Title className="school-title">
                      {school.name || school.id}{" "}
                    </Card.Title>
                  </div>
                ))
              ) : (
                <p>No schools available</p>
              )}
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <h2>Featured Products</h2>
              <div className="back-to-school-items">
                {featuredProducts.map((item, index) => (
                  <div key={index} className="item">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="item-image"
                    />
                    <h4>{item.title}</h4>
                    <p>{item.price}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-light text-center py-3">
        <div className="container">
          <p className="mb-0">
            &copy; 2024 Booklist Shop. All rights reserved.
          </p>
        </div>
      </footer>
      <BottomNav handleLogout={handleLogout} />
    </div>
  );
};

export default Home;
