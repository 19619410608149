import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaShoppingCart,
  FaHome,
  FaSignOutAlt,
  FaSearch,
  FaClipboardList,
  FaBars,
} from "react-icons/fa";
import "./Styles/Sidebar.css";

const BottomNav = ({ handleLogout }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <div className="bottom-nav d-md-none nus">
        <Link className="nav-link" to="/home">
          <FaHome className="icon" />
          Home
        </Link>
        <Link className="nav-link" to="/school">
          <FaSearch className="icon" />
          School
        </Link>
        <Link className="nav-link" to="/cart">
          <FaShoppingCart className="icon" />
          Cart
        </Link>

        <Link className="nav-link" onClick={toggleSidebar}>
          <FaBars className="icon" />
          More
        </Link>
      </div>

      {/* Sidebar */}
<div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
  <div className="sidebar-content">
    <Link className="nav-link" to="/orders">
      <FaSearch className="icon" /> Find Books
    </Link>
    <Link className="nav-link" to="/orders">
      <FaClipboardList className="icon" /> Orders
    </Link>
    <div className="sidebar-footer">
      <Link className="nav-link" onClick={handleLogout} to="/sign-in">
        <FaSignOutAlt className="icon" /> Logout
      </Link>
    </div>
  </div>
</div>

    </div>
  );
};

export default BottomNav;
