import axios from 'axios';

const SHIPDAY_API_URL = 'https://api.shipday.com';
const API_KEY = 'TUJkgrDfXS.Rc2DwlH0aaUWumiA1Kos';

// Create an Axios instance with the Shipday API base URL and authorization header
const api = axios.create({
  baseURL: SHIPDAY_API_URL,
  headers: {
    'Authorization': `Basic ${API_KEY}`,
    'Content-Type': 'application/json',
  },
});

// Function to create an order on Shipday
export const createOrder = async (orderData) => {
  try {
    const response = await api.post('/orders', orderData);
    return response.data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

// Function to get the status of an order
export const getOrderStatus = async (orderId) => {
  try {
    const response = await api.get(`/orders/${orderId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching order status:', error);
    throw error;
  }
};

// Function to track an order's delivery status
export const trackOrder = async (orderNumber) => {
  try {
    const hubris = await api.get(`/orders/${orderNumber}`);
    const response = hubris.data[0].orderStatus.orderState
    const response1 = hubris.data[0].activityLog.placementTime
    console.log(response1);
    return response

   
  } catch (error) {
    console.error('Error tracking order:', error);
    throw error;
  }
};

export const deleteOrder = async (orderId) => {
  try {
    const response = await api.delete(`/orders/${orderId}`);
    
    return response.data;
  } catch (error) {
    console.log(orderId);
    console.error('Error deleting order:', error);
    throw error;
  }
};
export const orderDate = async (orderNumber) => {
  try {
    const hubris = await api.get(`/orders/${orderNumber}`);
    
    const response = hubris.data[0].activityLog.placementTime
    
    return response

   
  } catch (error) {
    console.error('Error returning date order:', error);
    throw error;
  }
};
export const trackingLink = async (orderNumber) => {
  try {
    const hubris = await api.get(`/orders/${orderNumber}`);
    
    const response = hubris.data[0].trackingLink
    console.log(response)
    return response

   
  } catch (error) {
    console.error('Error returning date order:', error);
    throw error;
  }
};
export const getOrderId = async (orderNumber) => {
  try {
    const hubris = await api.get(`/orders/${orderNumber}`);
    
    const response = hubris.data[0].orderId
    console.log(response)
    return response

   
  } catch (error) {
    console.error('Error returning date order:', error);
    throw error;
  }
};

