import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import {  useNavigate } from 'react-router-dom';

import { database } from '../firebase/firebase';
import './AdminDashboard.css'; // Ensure styles are responsive
import moment from 'moment';
import { useAuth } from '../contexts/authContext';
import AdminSidebar from './AdminSidebar';


const AdminDashboard = () => {
  const [ordersBySchool, setOrdersBySchool] = useState({});
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0.0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalSchools, setTotalSchools] = useState(0);
  const [salesToday, setSalesToday] = useState(0.0);
  const [salesThisMonth, setSalesThisMonth] = useState(0.0);
  const [salesThisYear, setSalesThisYear] = useState(0.0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { logout, currentUser } = useAuth();
  

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = () => {
      const ordersRef = ref(database, 'orders/');
      onValue(ordersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const schoolOrderCount = {};
          let total = 0;
          let income = 0.0;
          let todaySales = 0.0;
          let monthSales = 0.0;
          let yearSales = 0.0;

          const now = moment();

          Object.keys(data).forEach((userId) => {
            const userOrders = data[userId];
            total += Object.keys(userOrders).length;

            Object.values(userOrders).forEach((order) => {
              const schoolName = order.school;
              schoolOrderCount[schoolName] = (schoolOrderCount[schoolName] || 0) + 1;
              income += order.totalOrderCost; // Sum the totalOrderCost for each order

              // Calculate sales by date
              const orderDate = moment(order.timestamp);

              if (orderDate.isSame(now, 'day')) {
                todaySales += order.totalOrderCost;
              }
              if (orderDate.isSame(now, 'month')) {
                monthSales += order.totalOrderCost;
              }
              if (orderDate.isSame(now, 'year')) {
                yearSales += order.totalOrderCost;
              }
            });
          });

          setOrdersBySchool(schoolOrderCount);
          setTotalOrders(total);
          setTotalIncome(income);
          setSalesToday(todaySales);
          setSalesThisMonth(monthSales);
          setSalesThisYear(yearSales);
        }
        setLoading(false);
      }, (error) => {
        setError(error.message);
        setLoading(false);
      });
    };

    const fetchUsers = () => {
      const usersRef = ref(database, 'users/');
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setTotalUsers(Object.keys(data).length);
        }
        setLoading(false);
      }, (error) => {
        setError(error.message);
        setLoading(false);
      });
    };
    
    const fetchSchools = () => {
      const schoolsRef = ref(database, 'schools/');
      onValue(schoolsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setTotalSchools(Object.keys(data).length); // Set the number of schools
        }
        setLoading(false);
      }, (error) => {
        setError(error.message);
        setLoading(false);
      });
    };

    fetchOrders();
    fetchUsers();
    fetchSchools();
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="admin-dashboard">
      <AdminSidebar handleLogout={handleLogout} />

      <main className="content">
        <h3>Order Summary</h3>
        <div className="summary-cards">
          <div className="card">
            <h4>Total Orders</h4>
            <p>{totalOrders}</p>
          </div>
          <div className="card">
            <h4>Total Sales</h4>
            <p>Kes {totalIncome.toFixed(2)}</p>
          </div>
          <div className="card">
            <h4>Total Schools</h4>
            <p>{totalSchools}</p>
          </div>
          <div className="card">
            <h4>Number of Users</h4>
            <p>{totalUsers}</p>
          </div>
          <div className="card">
            <h4>Total Sales Today</h4>
            <p>Kes {salesToday.toFixed(2)}</p>
          </div>
          <div className="card">
            <h4>Total Sales This Month</h4>
            <p>Kes {salesThisMonth.toFixed(2)}</p>
          </div>
          <div className="card">
            <h4>Total Sales This Year</h4>
            <p>Kes {salesThisYear.toFixed(2)}</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminDashboard;
