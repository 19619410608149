import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Card, Table } from "react-bootstrap";
import { database } from "../firebase/firebase";
import { ref, onValue, set } from "firebase/database";
import { useAuth } from "../contexts/authContext";
import { useCart } from "../contexts/cartContext";
import whatPng from "../images/file.png";
import whaPng from "../images/SD.png";
import BottomNav from "./bottomNav";
import Header from "./upperNav";
import { Link, useNavigate } from "react-router-dom";
import "./Styles/cart.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaShoppingCart,
  FaTrash,
  FaHome,
  FaSignOutAlt,
  FaSearch,
  FaClipboardList,
} from "react-icons/fa";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const { currentUser } = useAuth();
  const { cart } = useCart();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [showLoginSignup, setShowLoginSignup] = useState(false);
  const [bookCategories, setBookCategories] = useState({});

  useEffect(() => {
    if (currentUser) {
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);
      onValue(cartRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setCartItems(data);
        } else {
          setCartItems([]);
        }
      });

      const categoriesRef = ref(
        database,
        `users/${currentUser.uid}/bookCategories`
      );
      onValue(categoriesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setBookCategories(data);
        } else {
          setBookCategories({});
        }
      });
    } else {
      setCartItems([]);
      setBookCategories({});
    }
  }, [currentUser]);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleClearCart = () => {
    setCartItems([]);

    if (currentUser) {
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);
      set(cartRef, []);
    }
  };

  const handleQuantityChange = (book, newQuantity) => {
    // Prevent negative quantities and ensure newQuantity is a valid number
    if (newQuantity < 1 || isNaN(newQuantity)) {
      return;
    }
  
    const updatedCart = cartItems.map((item) =>
      item.name === book.name && item.school === book.school && item.grade === book.grade
        ? { ...item, quantity: newQuantity }
        : item
    );
    
    setCartItems(updatedCart);
  
    if (currentUser) {
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);
      set(cartRef, updatedCart);
    }
  };
  

  // Add item to cart or update quantity if it already exists
  const addToCart = (newBook) => {
    const existingItem = cartItems.find(
      (book) =>
        book.name === newBook.name &&
        book.school === newBook.school &&
        book.grade === newBook.grade
    );

    let updatedCart;
    if (existingItem) {
      // Update the quantity of the existing item
      updatedCart = cartItems.map((book) =>
        book.name === newBook.name &&
        book.school === newBook.school &&
        book.grade === newBook.grade
          ? { ...book, quantity: book.quantity + 1 } // Increase the quantity
          : book
      );
    } else {
      // Add new item with a default quantity of 1
      updatedCart = [...cartItems, { ...newBook, quantity: 1 }];
    }

    setCartItems(updatedCart);

    if (currentUser) {
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);
      set(cartRef, updatedCart);
    }
  };

  const handleRemoveFromCart = (bookToRemove) => {
    const updatedCart = cartItems.filter(
      (book) =>
        book.name !== bookToRemove.name ||
        book.school !== bookToRemove.school ||
        book.grade !== bookToRemove.grade
    );

    setCartItems(updatedCart);

    if (currentUser) {
      const cartRef = ref(database, `users/${currentUser.uid}/cart`);
      set(cartRef, updatedCart);
    }

    const updatedBookCategories = { ...bookCategories };
    delete updatedBookCategories[
      `${bookToRemove.name}-${bookToRemove.school}-${bookToRemove.grade}`
    ];
    setBookCategories(updatedBookCategories);
  };

  // Group cart items by school and grade
  const groupedCartItems = cartItems.reduce((acc, book) => {
    const key = `${book.school} - Grade ${book.grade}`;
    if (!acc[key]) acc[key] = [];
    acc[key].push(book);
    return acc;
  }, {});

  // Calculate total cost based on quantity
  const totalCost = cartItems.reduce(
    (total, book) => total + book.price * book.quantity,
    0
  );

  const toggleLoginSignup = () => {
    setShowLoginSignup(!showLoginSignup);
  };

  const handleContinueShopping = () => {
    navigate("/school");
  };

  const handleCheckout = () => {
    if (cartItems.length > 0) {
      navigate("/checkout");
    } else {
      alert(
        "Your cart is empty. Please add items to your cart before proceeding to checkout."
      );
    }
  };

  return (
    <section className="vh-100">
      <Header handleLogout={handleLogout} />
      <Container className="h-100 py-5">
      

        <div className="text-center mb-4">
          <h2>Your Cart</h2>
        </div>

        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-8">
              {Object.keys(groupedCartItems).length > 0 ? (
                Object.keys(groupedCartItems).map((groupKey, index) => (
                  <div
                    className="card mb-5 "
                    style={{ borderRadius: "16px", width: "100%" }}
                    key={index}
                  >
                    <div className="card-body p-4">
                      <h2>{groupKey}</h2>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Book Name</th>
                            <th>Type</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {groupedCartItems[groupKey].map((book, index) => (
                            <tr key={index}>
                              <td>
                                {" "}
                                {book.imageUrl && (
                                  <img
                                    src={book.imageUrl}
                                    alt={book.name}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      marginLeft: "10px",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </td>
                              <td>{book.name}</td>
                              <td>
                                {/* Display the book's category */}
                                {book.type}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  min="1"
                                  value={book.quantity}
                                  onChange={(e) =>
                                    handleQuantityChange(
                                      book,
                                      parseInt(e.target.value)
                                    )
                                  }
                                  onBlur={(e) =>
                                    handleQuantityChange(
                                      book,
                                      parseInt(e.target.value)
                                    )
                                  }
                                  style={{ width: "50px" }}
                                />
                              </td>
                              <td>
                                KES {(book.price * book.quantity).toFixed(2)}
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  onClick={() => handleRemoveFromCart(book)}
                                >
                                  <FaTrash />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Button
                        variant="danger"
                        className="mt-3"
                        onClick={handleClearCart}
                      >
                        Clear Cart
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <h1>
                    <p>Your cart is empty.</p>
                  </h1>
                </div>
              )}
            </div>

            <div className="col-md-4">
              {Object.keys(groupedCartItems).length > 0 && (
                <div className="card mb-5">
                  <div className="card-body p-4">
                    <p className="mb-0 me-5 d-flex align-items-center">
                      <span className="small text-muted me-2">
                        Order total:
                      </span>
                      <span className="lead fw-normal">
                        KES {totalCost.toFixed(2)}
                      </span>
                    </p>
                    <div className="d-grid gap-2 mt-3">
                      <Button
                        variant="success"
                        className="checkout-button"
                        onClick={handleCheckout}
                      >
                        Checkout
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <footer className="bg-light text-center py-3">
          <div className="container">
            <p className="mb-0">
              &copy; 2024 Booklist Shop. All rights reserved.
            </p>
          </div>
        </footer>
      </Container>
      <BottomNav handleLogout={handleLogout} />
    
    </section>
  );
};

export default Cart;
